import React, {useEffect, useState} from 'react';
import SideBarAdmin from "../../../components/SideBarAdmin";
import css from "./AllFlowsAdmin.module.css"
import {loadTeams, createFlow, loadAllFlows} from "../../../logic/api/api";
import TextInput from "../../../components/TextInput";
import Popup from "reactjs-popup";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Pool from "../../../logic/auth/UserPool";
import {validate} from "react-email-validator";
import Loading from "../../../components/Loading";
import {Link, NavLink} from "react-router-dom";
import BurgerMenuAdmin from "../../../components/BurgerMenuAdmin";
import useAlert from "../../../logic/alert/useAlert";
import CustomChatBot from "../../../components/CustomChatBot";

const AllFlowsAdmin = () => {

    const [teams, setTeams] = useState([])
    const [flows, setFlows] = useState([])
    const [email, setEmail] = useState([])
    const [selectedTeam, setSelectedTeam] = useState("")
    const [newFlowName, setNewFlowName] = useState("")
    const [loading, setLoading] = useState(true)
    const { setAlert } = useAlert()

    useEffect(() => {
        loadFlowsAndTeamsHandler();
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            console.log('enter')
        }
    }

    const validateInput = () => {
        if (newFlowName === "" || selectedTeam === "") {
            setAlert('Incorrect input.', 'error')
            return false
        }
        return true
    }

    const loadFlowsAndTeamsHandler = () => {
        setLoading(true)
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            console.log(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                setEmail(yourEmail)
                                loadTeams(yourEmail).then(response => {
                                    let data = []
                                    for (const team of response.data.teams) {
                                        data.push(team['TeamName']['S'])
                                    }
                                    setTeams(data)
                                }).catch(e => {
                                    console.error("LOAD TEAM ERROR")
                                    console.error(e)
                                })

                                loadAllFlows(yourEmail).then(response => {
                                    let data = []
                                    for (const flow of response.data.flows) {
                                        data.push(flow['FlowName']['S'])
                                    }
                                    setFlows(data)
                                    setLoading(false)
                                }).catch(e => {
                                    console.error("LOAD FLOWS ERROR")
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    const createFlowHandler = () => {
        setLoading(true)
        createFlow(newFlowName, selectedTeam, email).then(response => {
            loadFlowsAndTeamsHandler()
        }).catch(e => {
            console.error("CREATE FLOW ERROR")
            console.error(e)
        })
        setLoading(false)
    }

    return (
        <div>
            <CustomChatBot/>
            <BurgerMenuAdmin/>
            <div style={{display:"flex"}}>
                <style>{'body { background-color: #1d1d1d; }'}</style>
                <div>
                    <SideBarAdmin/>
                </div>
                <div style={{textAlign: "center", width: "100%"}}>
                    <div className={css.category}>Onboarding Flows
                        <Popup
                            trigger={ teams.length > 0 ? <div className={css.createButton} > Create flow + </div> : null}
                            modal
                            nested>
                            {close => (
                                <div className={css.modal}>
                                    <button className={css.close} onClick={close}>
                                        &times;
                                    </button>
                                    <div className={css.header}> Create onboarding flow </div>
                                    <TextInput  label="Onboarding flow name (it's good to put here role name e.g. Software Developer, DevOps, Project Manager etc.)"
                                                inputValue={newFlowName}
                                                onKeyDown={e => handleKeyDown(e)}
                                                inputChange={(value) => setNewFlowName(value)} autoComplete="off" />
                                    <div style={{margin:"10px 0"}}>Team</div>
                                    <Dropdown
                                        options={teams}
                                        onChange={e => setSelectedTeam(e.value)} value={"Select an option"} placeholder="Select an option" />
                                    <div
                                        className={css.createFlowPopupButton}
                                        onClick={() => {
                                            if(validateInput()) {
                                                createFlowHandler()
                                            }
                                            setNewFlowName("")
                                            close();
                                        }}>
                                        Create
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                    <div className={css.greyStripe} />
                    {loading ?
                        <Loading type={"spinningBubbles"} color={"#ffffff"} />
                        :
                        <div>
                            {typeof flows !== 'undefined' && flows.length > 0 ?
                                <div className={css.flowsContainer}>
                                    {flows.map((item, index) => (
                                        <Link key={index} style={{color:"#ffffff", textDecoration: "none"}} to={"/flows/"+item}>
                                            <div className={css.flowRectangleBox} >
                                                {item} flow
                                            </div>
                                        </Link>
                                    ))
                                    }
                                </div>
                                :
                                teams.length > 0 ?
                                <div style={{marginTop:"30px", color: "white"}}>Create your first flow to get started.</div>
                                    :
                                <NavLink to="/teams" ><div className={css.createTeamButton}> Create team + </div></NavLink>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default AllFlowsAdmin;