import { Alert } from '@mui/material';
import useAlert from '../../src/logic/alert/useAlert';

const AlertPopup = () => {
    const { text, type } = useAlert();

    if (text && type) {
        return (
            <Alert
                severity={type}
                sx={{
                    top: "10%",
                    margin:"0 auto",
                    left: "0",
                    right: "0",
                    width:"fit-content",
                    position: 'absolute',
                    zIndex: 10,
                }}
            >
                {text}
            </Alert>
        );
    } else {
        return <></>;
    }
};

export default AlertPopup;