import React, {useContext} from 'react';
import {Link, NavLink} from "react-router-dom";
import css from "./SideBarAdmin.module.css"
import {AccountContext} from "../pages/auth/Account";
import { MdSearch, MdPersonAddAlt, MdOutlineHome, MdOutlinePeopleAlt, MdGrading, MdOutlineSettings, MdOutlineLogout } from 'react-icons/md';

const SideBarAdmin = () => {
    const { logout } = useContext(AccountContext);

    const menuItem = [
        {
            path:"/",
            name:"Dashboard",
            icon:<MdOutlineHome style={{margin:"7px 5px 0 20px", display:"inline-block", color:"grey"}}/>
        },
        // {
        //     path:"/search",
        //     name:"Search",
        //     icon:<MdSearch style={{margin:"7px 5px 0 20px", display:"inline-block", color:"grey"}}/>
        // },
        {
            path:"/onboard",
            name:"Onboard",
            icon:<MdPersonAddAlt style={{margin:"7px 5px 0 20px", display:"inline-block", color:"grey"}}/>
        },
        {
            path:"/teams",
            name:"Teams",
            icon:<MdOutlinePeopleAlt style={{margin:"7px 5px 0 20px", display:"inline-block", color:"grey"}}/>
        },
        {
            path:"/flows",
            name:"Flows",
            icon:<MdGrading style={{margin:"7px 5px 0 20px", display:"inline-block", color:"grey"}}/>
        },
        {
            path:"/settings",
            name:"Settings",
            icon: <MdOutlineSettings style={{margin:"7px 5px 0 20px", display:"inline-block", color:"grey"}}/>
        },
    ]

    return (
        <div className={css.container}>
            <div className={css.sidebar}>
                <div className={css.top_section}>
                    <div className={css.logoContainer}>
                        <Link to="/"><img className={css.image} src={require('./whitePilowLogo.png')} /></Link>
                    </div>
                    <div className={css.bars}>
                        {menuItem.map((item, index) => (
                            <NavLink to={item.path} key={index} className={(navData) => (navData.isActive ? css.active : css.link)}>
                                <div className={css.box}>
                                    {item.icon}
                                    <div className={css.link_text}>{item.name}</div>
                                </div>
                            </NavLink>
                            ))
                        }
                            <NavLink onClick={logout} to="/login" className={(navData) => (navData.isActive ? css.active : css.link)}>
                                <div className={css.box}>
                                    <MdOutlineLogout size="20px" style={{margin:"7px 5px 0 20px", display:"inline-block", color:"grey"}}/>
                                    <div className={css.link_text}>Logout</div>
                                </div>
                            </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideBarAdmin;