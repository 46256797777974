import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import css from './BurgerMenuAdmin.module.css'
import {Link, NavLink} from "react-router-dom";
import {AccountContext} from "../pages/auth/Account";

const BurgerMenuAdmin = () => {

    const { logout } = useContext(AccountContext);

    return (
        <div style={{display:"flex"}}>
            <div className={css.burgerMenu}>
                <div className={css.navbar}>
                    <div className={css.container}>
                        <div className={css.navContainer}>
                            <input className={css.checkbox} type="checkbox" name="" id=""/>
                            <div className={css.hamburgerLines}>
                                <span className={css.line}></span>
                                <span className={css.line}></span>
                                <span className={css.line}></span>
                            </div>
                            <div className={css.menuItems}>
                                <NavLink to="/">Dashboard</NavLink>
                                <NavLink to="/search">Search</NavLink>
                                <NavLink to="/onboard">Onboard</NavLink>
                                <NavLink to="/teams">Teams</NavLink>
                                <NavLink to="/flows">Flows</NavLink>
                                <NavLink to="/settings">Settings</NavLink>
                                <NavLink onClick={logout} to="/login">Logout</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.logo}>
                <Link to="/"><img className={css.image} src={require('./whitePilowLogo.png')} /></Link>
            </div>
    </div>
    );
};


export default BurgerMenuAdmin;