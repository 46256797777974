import React, {useEffect, useState} from 'react';
import SideBarAdmin from "../../../components/SideBarAdmin";
import css from "./SettingsAdmin.module.css"
import {Link} from "react-router-dom";
import BurgerMenuAdmin from "../../../components/BurgerMenuAdmin";
import TextBlackInput from "../../../components/TextBlackInput";
import {validate} from "react-email-validator";
import Pool from "../../../logic/auth/UserPool";
import useAlert from "../../../logic/alert/useAlert";
import CustomChatBot from "../../../components/CustomChatBot";


const SettingsAdmin = () => {

    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [openedPasswordMenu, setOpenedPasswordMenu] = useState(false)
    const { setAlert } = useAlert()

    const validatePasswordInput = () => {
        if(password === null ) {
            setAlert('Incorrect old password.', 'error')
            return false
        }
        if (newPassword === null) {
            setAlert('Incorrect new password. Password minimum length is 8. Must have a special character, number, uppercase and lowercase letter.', 'error')
            return false
        }
        return true
    }

    const changePassword = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.changePassword(password, newPassword,function(err, result) {
                        if (err) {
                            console.log(err.message || JSON.stringify(err));
                        } else {
                            setAlert('Success', 'success')
                        }
                    })
                }
            })
        }
    }

    return (
        <div>
            <CustomChatBot/>
            <BurgerMenuAdmin/>
            <div style={{display:"flex"}}>
                <style>{'body { background-color: #1d1d1d; }'}</style>
                <div>
                    <SideBarAdmin/>
                </div>
                <div style={{textAlign: "center", width: "100%"}}>
                    <div className={css.category}>Settings</div>
                    <div className={css.greyStripe} />
                    <div className={css.mainBox}>
                        <Link to="/settings/searchbar"><div className={css.settingsButton}>Configure search bar</div></Link>
                        <div className={css.settingsButton} onClick={e => setOpenedPasswordMenu(true)}>Change Password</div>
                    </div>
                    {openedPasswordMenu ?
                        <div className={css.passwordContainer}>
                            <div className={css.categoryText}>Password Change</div>
                            <TextBlackInput type="password" onKeyDown={e => (e)} placeholder="Old password"
                                            inputValue={password} inputChange={(value) => setPassword(value)} autoComplete="off" />
                            <TextBlackInput type="password" onKeyDown={e => (e)} placeholder="New password"
                                            inputValue={newPassword} inputChange={(value) => setNewPassword(value)} autoComplete="off" />
                            <div className={css.saveButton} onClick={() => {
                                if(validatePasswordInput()) {
                                    changePassword()
                                }
                                setPassword("")
                                setNewPassword("")
                            }}> Save </div>
                        </div>
                        : null
                    }

                </div>
            </div>
        </div>
    );
};

export default SettingsAdmin;