import React from 'react';
import ReactLoading from 'react-loading';
import css from "./Loading.module.css";

const Loading = ({ type, color }) => (
    <div className={css.loadingRow}>
        <ReactLoading className={css.loadingComponent} type={type} color={color} height={50} width={50} />
    </div>
);

export default Loading;