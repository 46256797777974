import React from 'react';
import css from './BetaSign.module.css'


const BetaSign = () => {
    return (
        <div className={css.container}>
            <div className={css.text}>This is beta version.
                Please message us about bugs and suggestions at <a className={css.link} href="mailto:hello@pilow.io">hello@pilow.io</a></div>
        </div>
    );
};


export default BetaSign;