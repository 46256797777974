import React, { useState, useContext } from "react";
import Title from "../../components/Title";
import Text from "../../components/Text";
import {Link, useNavigate} from "react-router-dom";
import Button from "../../components/Button";
import { validate } from 'react-email-validator';
import { AccountContext } from "../auth/Account"
import validator from "validator";
import CenteredBoxContainer from "../../components/CenteredBoxContainer";
import TextInput from "../../components/TextInput";

const Login = () => {

    const [email, setEmail] = useState("");
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showCodeInput, setShowCodeInput] = useState(false)
    const [code, setCode] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("");
    const { initForgotPassword, confirmNewPassword } = useContext(AccountContext)
    const navigate = useNavigate();

    const forgotPassword = (event) => {
        event.preventDefault();

        if (checkEmailInputCorrectness()) {
            initForgotPassword(email)
                .then(data => {
                    console.log(data)
                    setShowCodeInput(true)
                })
                .catch(err => {
                    console.error("onFailure: ", err)
                    setErrorMessage(err.message)
                    setShowError(true)
                })
        } else {
            setShowError(true)
        }
    }

    const confirmCodeAndPassword = (event) => {
        event.preventDefault();

        if (checkCodeInputsCorrectness()) {
            confirmNewPassword(email, code, newPassword)
                .then(data => {
                    console.log(data)
                    navigate("/login")
                })
                .catch(err => {
                    console.error("onFailure: ", err)
                    setErrorMessage(err.message)
                    setShowError(true)
                })
        } else {
            setShowError(true)
        }
    }

    const checkEmailInputCorrectness = () => {
        setShowError(false)
        setErrorMessage("")

        if (email === "") {
            setErrorMessage("Field can't be empty.")
            return false
        }

        if (!validate(email)) {
            setErrorMessage("Incorrect email.")
            return false
        }
        return true
    }

    const checkCodeInputsCorrectness = () => {
        setShowError(false)
        setErrorMessage("")

        if (newPassword !== confirmPassword ) {
            setErrorMessage("Passwords are different.")
            return false
        }

        if (newPassword === "" || code === "") {
            setErrorMessage("Fields can't be empty.")
            return false
        }

        if (!validator.isStrongPassword(newPassword, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorMessage("Password minimum length is 8. Must have a special character, number, uppercase and lowercase letter.")
            return false
        }
        return true
    }

    return (
        <div>
            {!showCodeInput ?
                <CenteredBoxContainer title="Forgot password">
                    <Text text="Enter your email associated with your account and we’ll send an email with a code"/>
                    <div style={{marginTop: 40}} />
                    <TextInput label="Email" inputValue={email} inputChange={(value) => setEmail(value)} autoComplete="off"/>
                    <br/>
                    {showError ?
                        <div className="errorText"style={{margin: "20px 0px"}}>
                            <p>{errorMessage + " If you need help, contact us at hello@pilow.io"}</p>
                        </div>
                        : null}
                    <br/>
                    <Button handleClick={forgotPassword} text="Send code" filled={true}/>
                    <br/>
                    <label htmlFor="info">Don't have an account? </label>
                    <Link style={{textDecoration: "none"}} to="/signup"><label style={{color: "#2D8BF6"}}>Sign up </label></Link>
                </CenteredBoxContainer>
                :
                <CenteredBoxContainer title="New password">
                        <Text text="Enter new password and verification code from your email"/>
                        <div style={{marginTop: 40}} />
                        <TextInput label="New password" inputValue={newPassword} type="password" inputChange={(value) => setNewPassword(value)} autoComplete="new-password"/>
                        <br/>
                        <TextInput label="Confirm password" inputValue={confirmPassword} type="password" inputChange={(value) => setConfirmPassword(value)} autoComplete="new-password"/>
                        <br/>
                        <TextInput label="Verification code" inputValue={code} type="password" inputChange={(value) => setCode(value)} autoComplete="new-password"/>
                        {showError ?
                            <div className="errorText"style={{margin: "20px 0px"}}>
                                <p>{errorMessage + " If you need help, contact us at hello@pilow.io"}</p>
                            </div>
                            : null}
                        <br/>
                        <Button handleClick={confirmCodeAndPassword} text="Change password" filled={true}/>
                        <br/>
                        <label htmlFor="info">Don't have an account? </label>
                        <Link style={{textDecoration: "none"}} to="/signup"><label style={{color: "#2D8BF6"}}>Sign up </label></Link>
                </CenteredBoxContainer>
            }
        </div>
    );
};

export default Login;