import React, { useEffect, useState} from 'react';
import SideBarAdmin from "../../../components/SideBarAdmin";
import css from "./TeamsAdmin.module.css";
import {createTeam, loadTeams, addMember, removeMemberFromTeam} from "../../../logic/api/api";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Pool from "../../../logic/auth/UserPool";
import {validate} from "react-email-validator";
import TextInput from "../../../components/TextInput";
import {Link} from "react-router-dom";
import Loading from "../../../components/Loading";
import { MdPerson } from 'react-icons/md';
import BurgerMenuAdmin from "../../../components/BurgerMenuAdmin";
import useAlert from "../../../logic/alert/useAlert";
import CustomChatBot from "../../../components/CustomChatBot";
import "./../../../components/Popup.css"
const TeamsAdmin = () => {

    const [teams, setTeams] = useState([])
    const [email, setEmail] = useState("")
    const [teamName, setTeamName] = useState("")
    const [loading, setLoading] = useState(true)
    const [newMemberEmail, setNewMemberEmail] = useState("")
    const { setAlert } = useAlert()

    // const contentStyle = { width: "80%" };


    useEffect(() => {
        loadTeamsHandler();
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            console.log('enter')
        }
    }

    const loadTeamsHandler = () => {
        setLoading(true)
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            console.error(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                setEmail(yourEmail)
                                loadTeams(yourEmail).then(response => {
                                    setTeams(response.data.teams)
                                    setLoading(false)
                                }).catch(e => {
                                    setLoading(false)
                                    console.error("LOAD TEAM ERROR")
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    const createTeamHandler = () => {

        if (teamName === "") {
            setLoading(false)
            return
        }

        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            console.error(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                setEmail(result[i].getValue())
                            }
                        }

                        createTeam(teamName, email).then(response => {
                            loadTeamsHandler()
                        }).catch(e => {
                            setLoading(false)
                            console.error("CREATE TEAM ERROR")
                            console.error(e)
                        })
                    });
                }
            })
        }
        setLoading(false)
    }

    const validateEmail = () => {
        if (!validate(newMemberEmail)) {
            setAlert('Incorrect email.', 'error')
            return false
        }
        return true
    }

    const addMemberHandler = (team) => {
        setLoading(true)

        if(email === newMemberEmail) {
            setNewMemberEmail("")
            return
        }

        addMember(email, newMemberEmail, team).then(response => {
            loadTeamsHandler()
        }).catch(e => {
            console.error("ADD MEMBER TO TEAM ERROR")
            console.error(e)
        })
        setTeamName("")
        setNewMemberEmail("")
        setLoading(false)
    }

    const removeMemberFromTeamHandler = (email, teamName) => {
        setLoading(true)
        removeMemberFromTeam(email, teamName).then(response => {
            loadTeamsHandler()
        }).catch(e => {
            console.error("REMOVE MEMBER FROM TEAM ERROR")
            console.error(e)
        })
        setLoading(false)
    }

    const validateTeamNameInput = () => {
        console.log('a')
        if (teamName === "") {
            setAlert('Incorrect name.', 'error')
            return false
        }
        return true
    }

    return (
        <div>
            <CustomChatBot/>
            <BurgerMenuAdmin/>
            <div style={{display:"flex"}}>
                <style>{'body { background-color: #1d1d1d; }'}</style>
                <div>
                    <SideBarAdmin/>
                </div>
                <div style={{textAlign: "center", width: "100%"}}>
                    <div className={css.category}>Teams
                        <Popup
                            trigger={<div className={css.createButton} > Create team + </div>}
                            modal
                            nested>
                            {close => (
                                <div className={css.modal}>
                                    <button className={css.close} onClick={close}>
                                        &times;
                                    </button>
                                    <div className={css.header}> Create team </div>
                                    <TextInput  label="Team name"
                                                onKeyDown={e => handleKeyDown(e)}
                                                inputValue={teamName} inputChange={(value) => setTeamName(value)} autoComplete="off" />
                                    <div
                                        className={css.createTeamPopupButton}
                                        onClick={() => {
                                            setLoading(true)
                                            if(validateTeamNameInput()) {
                                                setTeamName("")
                                                createTeamHandler()
                                                close();
                                            } else {
                                                close();
                                            }
                                        }}>
                                        Create
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                    <div className={css.greyStripe} />
                    {loading ?
                        <Loading type={"spinningBubbles"} color={"#ffffff"} />
                        :
                        <div>
                            {typeof teams !== 'undefined' && teams.length > 0 ?
                                <div>
                                    {teams.map((item, index) => (
                                        <div className={css.teamRectangleBox} key={index}>
                                            {item.TeamName.S} Team
                                            {/*<Popup*/}
                                            {/*    trigger={<div className={css.addMemberButton}> Add Member + </div>}*/}
                                            {/*    modal*/}
                                            {/*    nested>*/}
                                            {/*    {close => (*/}
                                            {/*        <div className={css.modal}>*/}
                                            {/*            <button className={css.close} onClick={close}>*/}
                                            {/*                &times;*/}
                                            {/*            </button>*/}
                                            {/*            <div className={css.header}> Invite member </div>*/}
                                            {/*            <TextInput  label="Email address" inputValue={newMemberEmail} inputChange={(value) => setNewMemberEmail(value)} autoComplete="off" />*/}
                                            {/*            <div*/}
                                            {/*                className={css.createTeamPopupButton}*/}
                                            {/*                onClick={() => {*/}
                                            {/*                    if(validateEmail()){*/}
                                            {/*                        close();*/}
                                            {/*                        addMemberHandler(item.TeamName.S);*/}
                                            {/*                    }*/}
                                            {/*                }}>*/}
                                            {/*                Sent invitation link*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    )}*/}
                                            {/*</Popup>*/}
                                            {/*<div className={css.addMemberButton}> Edit </div>*/}
                                            <div className={css.userContainer}>
                                                {item.Members.SS.map((email, index) => (
                                                    <div key={index} className={css.userRow}>
                                                        <MdPerson style={{marginTop:"17px"}} size="20px"/>
                                                        {/*<Link className={css.userLink} to={"/teams/user/"+email}> {email} </Link>*/}
                                                        <div className={css.userLink}>{email}</div>
                                                        <div onClick={(e) => removeMemberFromTeamHandler(email, item.TeamName.S)} className={css.removeFromTeamButton}>Remove</div>
                                                    </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        ))
                                    }
                                </div>
                                :
                                <div style={{marginTop:"30px", color: "white"}}>Create your first team to get started.</div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default TeamsAdmin;