import React from 'react';
import "./Title.module.css"

const Title = ({text}) => {
    return (
        <div>
            <h1>{text}</h1>
        </div>
    );
};

export default Title;