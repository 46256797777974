import React, {useEffect, useState} from "react";
import UserPool from "../../logic/auth/UserPool";
import Text from "../../components/Text";
import Button from "../../components/Button";
import {Link, useSearchParams, useNavigate} from "react-router-dom";
import {res, validate} from 'react-email-validator';
import validator from 'validator'
import CenteredBoxContainer from "../../components/CenteredBoxContainer";
import TextInput from "../../components/TextInput";
import css from "./IntroStylesheet.module.css"
import {checkCompanyExistence, createCompany, createUser, joinAsMember} from "../../logic/api/api.js"
import ButtonLoading from "../../components/ButtonLoading";

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [companyName, setCompanyName] = useState("")
    const [companyCode, setCompanyCode] = useState("")
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [buttonLoading, setButtonloading] = useState(false);

    useEffect(() => {
        if(searchParams.get("company")) {
            setCompanyCode(searchParams.get("company"))
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            signUp(e)
        }
    }

    const signUp = (event) => {
        event.preventDefault();
        setShowError(false)
        setButtonloading(true)

        if (checkInputsCorrectness()) {

            if (companyName !== "") {
                checkCompanyExistence(companyName).then(resp => {
                    if(!resp.data.exist) {
                        UserPool.signUp(email, password, [
                            {"Name":"given_name","Value":givenName},
                            {"Name":"family_name","Value":familyName}
                        ], null, (err, data) => {
                            if (err) {
                                setErrorMessage(err + " If you need help, contact us at hello@pilow.io");
                                setShowError(true)
                                setButtonloading(false)
                                console.error(err)
                                return
                            }

                            // ### CREATE COMPANY ###
                            createCompany(companyName, email).then(response => {
                                // ### CREATE USER ###
                                createUser(companyName, email, familyName, givenName, 'Administrator').then(response => {
                                    navigate("/login?email=1")
                                }).catch(e => {
                                    console.log("CREATE USER ERROR")
                                    console.log(e)
                                    setErrorMessage(e.message + ". Please contact support team at hello@pilow.io about this issue.")
                                    setShowError(true)
                                    setButtonloading(false)
                                    return
                                })
                            }).catch(e => {
                                console.log("CREATE COMPANY ERROR")
                                console.log(e)
                                setErrorMessage(e.message + ". Please contact support team at hello@pilow.io about this issue.")
                                setShowError(true)
                                setButtonloading(false)
                                return
                            })

                        });
                    } else {
                        setErrorMessage(resp.data.message)
                        setShowError(true)
                        setButtonloading(false)
                    }
                }).catch(e => {
                    console.log(e)
                    console.log(e)
                    setErrorMessage(e.message + ". Please contact support team at hello@pilow.io about this issue.")
                    setShowError(true)
                    setButtonloading(false)
                    return
                })
            }

            if (companyCode !== "") {
                UserPool.signUp(email, password, [
                    {"Name":"given_name","Value":givenName},
                    {"Name":"family_name","Value":familyName}
                ], null, (err, data) => {
                    if (err) {
                        setErrorMessage(err + " If you need help, contact us at hello@pilow.io");
                        setShowError(true)
                        setButtonloading(false)
                        console.error(err)
                        return
                    }

                    joinAsMember(companyCode, email, familyName, givenName, 'Basic').then(response => {
                        navigate("/login?email=1")
                    }).catch(e => {
                        console.log("JOIN AS MEMBER ERROR")
                        console.log(e)
                        setErrorMessage(e.message + ". Please contact support team at hello@pilow.io about this issue.")
                        setShowError(true)
                        setButtonloading(false)
                        return
                    })

                })
            }


        } else {
            setShowError(true)
            setButtonloading(false)
        }
    };

    const checkInputsCorrectness = () => {
        setShowError(false)
        setErrorMessage("")

        if (email === "" || password === "" || givenName === "" || familyName === "" || confirmPassword === "" ) {
            setErrorMessage("Fields can't be empty.")
            return false
        }

        if (password !== confirmPassword ) {
            setErrorMessage("Passwords are different.")
            return false
        }

        if (companyName === "" && companyCode === "" ) {
            setErrorMessage("Fields a can't be empty.")
            return false
        }

        if (!validate(email)) {
            setErrorMessage("Incorrect email.")
            return false
        }

        if (!validator.isStrongPassword(password, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorMessage("Password minimum length is 8. Must have a special character, number, uppercase and lowercase letter.")
            return false
        }
        return true
    }

    return (
        <div>
            <div>
                <CenteredBoxContainer title="Sign up to Pilow">
                    <TextInput label={companyCode ? "Email address that received the invitation link:" : "Email" } onKeyDown={e => handleKeyDown(e)} inputValue={email} inputChange={(value) => setEmail(value)} autoComplete="off"/>
                    <br/>
                    <TextInput  label="First name" inputValue={givenName} onKeyDown={e => handleKeyDown(e)} inputChange={(value) => setGivenName(value)} autoComplete="off" />
                    <br/>
                    <TextInput  label="Last name" inputValue={familyName} onKeyDown={e => handleKeyDown(e)} inputChange={(value) => setFamilyName(value)} autoComplete="off" />
                    <br/>
                    <TextInput label="Password" inputValue={password} type="password" onKeyDown={e => handleKeyDown(e)} inputChange={(value) => setPassword(value)} autoComplete="new-password"/>
                    <br/>
                    <TextInput label="Confirm password" inputValue={confirmPassword} type="password" onKeyDown={e => handleKeyDown(e)} inputChange={(value) => setConfirmPassword(value)} autoComplete="off"/>
                    <br/>
                    {companyCode ?
                        <TextInput disabled="true" label="Your company ID" onKeyDown={e => handleKeyDown(e)} inputValue={companyCode} autoComplete="off" />
                        :
                        <TextInput label="New company name" inputValue={companyName} onKeyDown={e => handleKeyDown(e)} inputChange={(value) => setCompanyName(value)} autoComplete="off" />
                    }
                    {showError ?
                        <div className={css.errorText} style={{margin: "20px 0px"}}>
                            <p>{errorMessage}</p>
                        </div>
                        : null}
                    <br/>
                    {buttonLoading ?
                        <ButtonLoading />
                        :
                        <Button onKeyDown={e => handleKeyDown(e)} handleClick={signUp} text="Sign up" filled={true}/>
                    }
                    <br/>
                    <label htmlFor="info">You already have an account? </label>
                    <Link style={{textDecoration: "none"}} to="/login"><label style={{cursor:"pointer",color: "#2D8BF6"}}>Login</label></Link>
                </CenteredBoxContainer>
            </div>
        </div>
    );
};

export default Signup;

