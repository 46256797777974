import React from 'react';
import css from "./TextBlackInput.module.css"

const TextBlackInput = ({label, inputValue, inputChange, type, placeholder, autoComplete, disabled, onKeyDown}) => {
    return (
        <div className={css.inputBox}>
            <label>{label}</label>
            <input
                autoFocus
                onKeyDown={(e) => onKeyDown(e)}
                className={css.textInput} value={inputValue}
                onChange={(event) => inputChange(event.target.value)}
                type={type}
                placeholder={placeholder}
                style={disabled ?  {pointerEvents: "none"} : {}}
                autoComplete={autoComplete}>
            </input>
        </div>
    );
};

export default TextBlackInput;