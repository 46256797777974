import React from 'react';
import { render } from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom"
import {Account} from "./pages/auth/Account";
import {AlertProvider} from "./logic/alert/AlertContext";

render(
  <React.StrictMode>
      <BrowserRouter>
          <Account>
              <AlertProvider>
                <App />
              </AlertProvider>
          </Account>
      </BrowserRouter>
  </React.StrictMode>,
    document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
