import React, {useEffect, useState} from 'react';
import SideBarAdmin from "../../../components/SideBarAdmin";
import css from "./SingleFlowAdmin.module.css";
import {Link, useNavigate, useParams} from "react-router-dom";
import Pool from "../../../logic/auth/UserPool";

import {createTask, loadFlow, loadAllTaskForFlow, editTask, deleteTask, addCollaborator, removeCollaborator, deleteFlow} from "../../../logic/api/api";
import TextInput from "../../../components/TextInput";
import Dropdown from "react-dropdown";
import Popup from "reactjs-popup";
import Loading from "../../../components/Loading";
import {MdExpandLess, MdExpandMore} from 'react-icons/md';
import {validate} from "react-email-validator";
import BurgerMenuAdmin from "../../../components/BurgerMenuAdmin";
import useAlert from "../../../logic/alert/useAlert";

const SingleFlowAdmin = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [email, setEmail] = useState("")
    const [flowData, setFlowData] = useState({})
    const [tasksList, setTasksList] = useState([])

    // CREATE FORM
    const [selectedTaskType, setSelectedTaskType] = useState("")
    const [taskName, setTaskName] = useState("")
    const [taskNameOld, setTaskNameOld] = useState("")
    const [description, setDescription] = useState("")
    const [importantLinks, setImportantLinks] = useState("")
    const [responsibleContact, setResponsibleContact] = useState("")

    // EDIT FORM
    const [editSelectedTaskType, setEditSelectedTaskType] = useState("")
    const [editTaskName, setEditTaskName] = useState("")
    const [editTaskNameOld, setEditTaskNameOld] = useState("")
    const [editDescription, setEditDescription] = useState("")
    const [editImportantLinks, setEditImportantLinks] = useState("")
    const [editResponsibleContact, setEditResponsibleContact] = useState("")

    const [loading, setLoading] = useState(true)
    const [categories, setCategories] = useState([])
    const [openedTaskIndex, setOpenedTaskIndex] = useState(-1)
    const [collaboratorsEmail, setCollaboratorsEmail] = useState("")
    const [removeCollaboratorEmail, setRemoveCollaboratorEmail] = useState("")

    const { setAlert } = useAlert()

    useEffect(() => {
        loadFlowAndTasksHandler();
    }, []);

    let taskOptions = [
        "Access",
        "Reading",
        "Meeting",
        "Training",
        "Hardware",
        "Other"
    ]

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
        }
    }

    const loadFlowAndTasksHandler = () => {
        setLoading(true)
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            console.error(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                setEmail(yourEmail)
                                loadFlow(id, yourEmail).then(response => {
                                    let flow = response['data']['data']
                                    setFlowData(flow)
                                    let flowName = flow['FlowName']['S']

                                    if(flow['Tasks']) {
                                        loadAllTaskForFlow(flowName, yourEmail).then(response => {
                                            //TODO what if task not found?
                                            let tasks = response['data']['data']['tasks']
                                            let categories = response['data']['data']['categories']
                                            setTasksList(tasks)
                                            setCategories(categories)
                                            setLoading(false)
                                        }).catch(e => {
                                            console.error("LOAD TASK ERROR")
                                            console.error(e)
                                            setLoading(false)
                                        })
                                    } else {
                                        setLoading(false)
                                    }

                                }).catch(e => {
                                    console.error("LOAD FLOW ERROR")
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    const createTaskHandler = () => {
        setLoading(true)
        createTask(email, flowData['FlowName']['S'],  selectedTaskType, taskName, description, importantLinks, responsibleContact).then(response => {
            loadFlowAndTasksHandler()
        }).catch(e => {
            console.error("CREATE TASK ERROR")
            console.error(e)
        })
        setLoading(false)
    }

    const editTaskHandler = () => {
        setLoading(true)
        editTask(email, flowData['FlowName']['S'],  editSelectedTaskType, editTaskName, editTaskNameOld, editDescription, editImportantLinks, editResponsibleContact).then(response => {
            loadFlowAndTasksHandler()
        }).catch(e => {
            console.error("EDIT TASK ERROR")
            console.error(e)
        })
        setLoading(false)
        setOpenedTaskIndex(-1)
    }

    const deleteTaskHandler = () => {
        setLoading(true)
        deleteTask(email, flowData['FlowName']['S'], editTaskName).then(response => {
            loadFlowAndTasksHandler()
        }).catch(e => {
            console.error("DELETE TASK ERROR")
            console.error(e)
        })
        setLoading(false)
        setOpenedTaskIndex(-1)
    }

    const addCollaboratorHandler = () => {
        addCollaborator(email, flowData['FlowName']['S'], collaboratorsEmail).then(response => {
            loadFlowAndTasksHandler()
        }).catch(e => {
            console.error("ADD COLLABORATOR ERROR")
            console.error(e)
        })
    }

    const removeCollaboratorHandler = () => {
        removeCollaborator(email, flowData['FlowName']['S'], removeCollaboratorEmail).then(response => {
            loadFlowAndTasksHandler()
        }).catch(e => {
            console.error("REMOVE COLLABORATOR ERROR")
            console.error(e)
        })
    }

    const deleteFlowHandler = () => {
        deleteFlow(email, flowData['FlowName']['S']).then(response => {
            navigate("/flows")
        }).catch(e => {
            console.error("DELETE FLOW ERROR")
            console.error(e)
        })
    }

    const validateInput = () => {
        if (selectedTaskType === "" || selectedTaskType === "Select a type" || taskName === "" || description === "" ) {
            setAlert('Incorrect input.', 'error')
            return false
        }
        return true
    }
    const validateEditInput = () => {
        if (editSelectedTaskType === "" || editSelectedTaskType === "Select a type" || editTaskName === "" || editDescription === "" ) {
            setAlert('Incorrect input.', 'error')
            return false
        }
        return true
    }

    const validateAddCollaboratorInput = () => {
        if (!validate(collaboratorsEmail)) {
            setAlert('Incorrect input.', 'error')
            return false
        }
        return true
    }

    const validateRemoveCollaboratorInput = () => {
        if (!validate(removeCollaboratorEmail)) {
            setAlert('Incorrect input.', 'error')
            return false
        }
        return true
    }


    const fillTaskBox = (taskName, taskType, description, links, responsible) => {
        setEditTaskNameOld(taskName)
        setEditTaskName(taskName)
        setEditSelectedTaskType(taskType)
        setEditDescription(description)
        setEditImportantLinks(links)
        setEditResponsibleContact(responsible)
    }

    return (
        <div>
            <BurgerMenuAdmin/>
            <div style={{display:"flex"}}>
                <style>{'body { background-color: #1d1d1d; }'}</style>
                <div>
                    <SideBarAdmin/>
                </div>
                <div style={{textAlign: "center", width: "100%"}}>
                    <div className={css.category}>{id} - onboarding flow
                        <Popup
                            trigger={<div className={css.createButton} > Create task </div>}
                            modal
                            nested>
                            {close => (
                                <div className={css.modal}>
                                    <button className={css.close} onClick={close}>
                                        &times;
                                    </button>
                                    <div className={css.header}> Create task </div>
                                    <div style={{margin:"10px 0"}}>Task type</div>
                                    <Dropdown
                                        options={taskOptions}
                                        onChange={e => setSelectedTaskType(e.value)} value={"Select a type"} placeholder="Select an option" />
                                    <div style={{margin:"20px 0"}}/>
                                    <TextInput  label="Task name (e.g. 'Github repo access', 'Read infrastructure documentation', 'Perform safety training')"
                                                onKeyDown={e => handleKeyDown(e)}
                                                inputValue={taskName} inputChange={(value) => setTaskName(value)} autoComplete="off" />
                                    <TextInput  label="Description"
                                                onKeyDown={e => handleKeyDown(e)}
                                                inputValue={description} inputChange={(value) => setDescription(value)} autoComplete="off" />
                                    <TextInput  label="Important links (Optional)"
                                                onKeyDown={e => handleKeyDown(e)}
                                                inputValue={importantLinks} inputChange={(value) => setImportantLinks(value)} autoComplete="off" />
                                    <TextInput  label="Contact to the person that is responsible (e.g. email, phone number) (Optional)"
                                                onKeyDown={e => handleKeyDown(e)}
                                                inputValue={responsibleContact} inputChange={(value) => setResponsibleContact(value)} autoComplete="off" />

                                    <div
                                        className={css.createTaskPopupButton}
                                        onClick={() => {
                                            if(validateInput()) {
                                                createTaskHandler()
                                            }
                                            setSelectedTaskType("")
                                            setTaskName("")
                                            setDescription("")
                                            setImportantLinks("")
                                            setResponsibleContact("")
                                            close();
                                        }}>
                                        Save
                                    </div>
                                </div>
                            )}
                        </Popup>
                        {/*<Popup*/}
                        {/*    trigger={<div className={css.createButton} > Add Collaborator </div>}*/}
                        {/*    modal*/}
                        {/*    nested>*/}
                        {/*    {close => (*/}
                        {/*        <div className={css.modal}>*/}
                        {/*            <button className={css.close} onClick={close}>*/}
                        {/*                &times;*/}
                        {/*            </button>*/}
                        {/*            <div className={css.header}> Add collaborator </div>*/}
                        {/*            <div style={{margin:"10px 0"}}>Current collaborators:</div>*/}
                        {/*            <ul>*/}
                        {/*            {flowData['Collaborators'] ?  flowData['Collaborators']['SS'].map((item, index) => (*/}
                        {/*                <li key={index}><div style={{margin:"10px 0"}}>{item}</div></li>*/}
                        {/*                )) : null*/}
                        {/*            }*/}
                        {/*            </ul>*/}

                        {/*            <TextInput  label="Email of new collaborator"*/}
                        {/*                        inputValue={collaboratorsEmail} inputChange={(value) => setCollaboratorsEmail(value)} autoComplete="off" />*/}
                        {/*            <div*/}
                        {/*                className={css.createTaskPopupButton}*/}
                        {/*                onClick={() => {*/}
                        {/*                    if(validateAddCollaboratorInput()) {*/}
                        {/*                        addCollaboratorHandler()*/}
                        {/*                    }*/}
                        {/*                    setCollaboratorsEmail("")*/}
                        {/*                    close();*/}
                        {/*                }}>*/}
                        {/*                Add*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*</Popup>*/}
                        {/*<Popup*/}
                        {/*    trigger={<div className={css.createButton} > Remove Collaborator </div>}*/}
                        {/*    modal*/}
                        {/*    nested>*/}
                        {/*    {close => (*/}
                        {/*        <div className={css.modal}>*/}
                        {/*            <button className={css.close} onClick={close}>*/}
                        {/*                &times;*/}
                        {/*            </button>*/}
                        {/*            <div className={css.header}> Remove collaborator </div>*/}
                        {/*            <div style={{margin:"10px 0"}}>Current collaborators:</div>*/}
                        {/*            <ul>*/}
                        {/*                {flowData['Collaborators'] ?  flowData['Collaborators']['SS'].map((item, index) => (*/}
                        {/*                    <li key={index}><div style={{margin:"10px 0"}}>{item}</div></li>*/}
                        {/*                )) : null*/}
                        {/*                }*/}
                        {/*            </ul>*/}

                        {/*            <TextInput  label="Email of collaborator you want to be removed"*/}
                        {/*                        inputValue={removeCollaboratorEmail} inputChange={(value) => setRemoveCollaboratorEmail(value)} autoComplete="off" />*/}
                        {/*            <div*/}
                        {/*                className={css.createTaskPopupButton}*/}
                        {/*                onClick={() => {*/}
                        {/*                    if(validateRemoveCollaboratorInput()) {*/}
                        {/*                        removeCollaboratorHandler()*/}
                        {/*                    }*/}
                        {/*                    setRemoveCollaboratorEmail("")*/}
                        {/*                    close();*/}
                        {/*                }}>*/}
                        {/*                Add*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*</Popup>*/}
                        <Popup
                            trigger={<div className={css.createButton} > Delete flow </div>}
                            modal
                            nested>
                            {close => (
                                <div className={css.modal}>
                                    <button className={css.close} onClick={close}>
                                        &times;
                                    </button>
                                    <div style={{margin:"10px 0", textAlign:"center"}}>Are you sure you want to remove the flow?</div>
                                    <div
                                        className={css.createTaskPopupButton}
                                        onClick={() => {
                                            deleteFlowHandler()
                                            close();
                                        }}>
                                        Yes
                                    </div>
                                    <div
                                        className={css.deleteFlowButton}
                                        onClick={() => {
                                            close();
                                        }}>
                                        Cancel
                                    </div>
                                </div>
                            )}
                        </Popup>

                    </div>
                    <div className={css.greyStripe} />
                    {loading ?
                        <Loading type={"spinningBubbles"} color={"#ffffff"} />
                        :
                        <div>
                            {typeof tasksList !== 'undefined' && tasksList.length > 0 ?
                                <div className={css.tasksContainer}>
                                    {categories.map((categoryName, index) => (
                                        <div key={index}>
                                            <div className={css.taskCategoryTitle} >
                                                {categoryName}
                                            </div>
                                            {tasksList.map((item, index) => (
                                                item['TaskCategory']['S'] === categoryName ?
                                                    <div key={index}>
                                                        <div style={{color:"#ffffff", textDecoration: "none"}} onClick={e => {
                                                            e.preventDefault()
                                                            let importantLinks = 'ImportantLinks' in item ? item['ImportantLinks']['S'] : ''
                                                            let responsibleContact = 'ResponsibleContact' in item ? item['ResponsibleContact']['S'] : ''
                                                            fillTaskBox(item['TaskName']['S'], item['TaskCategory']['S'], item['Description']['S'],importantLinks, responsibleContact)
                                                            openedTaskIndex === index ? setOpenedTaskIndex(-1) : setOpenedTaskIndex(index)}}
                                                        >
                                                            <div className={css.taskRectangleBox} style={openedTaskIndex === index ? {border: "none"} : {borderBottom: "0.5px solid #414141"}} >
                                                                {openedTaskIndex === index ?
                                                                <MdExpandLess size={"14px"}  style={{margin:"0 7px 0 0", display:"inline-block", color:"white"}}/>
                                                                    :
                                                                <MdExpandMore size={"14px"}  style={{margin:"0 7px 0 0", display:"inline-block", color:"white"}}/>
                                                                }
                                                                <div className={css.taskName}>{item['TaskName']['S']} </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            openedTaskIndex === index ?
                                                                <div className={css.openedTaskBoxContainer}>
                                                                    Description:
                                                                    <div className={css.textLineContainer}>
                                                                        {item['Description']['S']}
                                                                    </div>
                                                                    {'ImportantLinks' in item ?
                                                                        <>
                                                                            Important Links:
                                                                            <div className={css.textLineContainer}>
                                                                               <a style={{color: "lightskyblue", textDecoration:"underline"}} href={item['ImportantLinks']['S']}>{item['ImportantLinks']['S']}</a>
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                    }
                                                                    {'ResponsibleContact' in item ?
                                                                        <>
                                                                            Person responsible:
                                                                            <div className={css.textLineContainer}>
                                                                                {item['ResponsibleContact']['S']}
                                                                            </div>
                                                                        </>
                                                                        : null
                                                                    }
                                                                    <Popup
                                                                        trigger={<div className={css.editButton} > Edit </div>}
                                                                        modal
                                                                        nested>
                                                                        {close => (
                                                                            <div className={css.modal}>
                                                                                <button className={css.close} onClick={close}>
                                                                                    &times;
                                                                                </button>
                                                                                <div className={css.header}> Edit task </div>
                                                                                <div style={{margin:"10px 0"}}>Task type</div>
                                                                                <Dropdown
                                                                                    options={taskOptions}
                                                                                    onChange={e => setEditSelectedTaskType(e.value)} value={editSelectedTaskType} placeholder="Select an option" />
                                                                                <div style={{margin:"20px 0"}}/>
                                                                                <TextInput  label="Task name (e.g. 'Github repo access', 'Read infrastructure documentation', 'Perform safety training')"
                                                                                            inputValue={editTaskName}
                                                                                            onKeyDown={e => handleKeyDown(e)}
                                                                                            inputChange={(value) => setEditTaskName(value)} autoComplete="off" />
                                                                                <TextInput  label="Description"
                                                                                            inputValue={editDescription}
                                                                                            onKeyDown={e => handleKeyDown(e)}
                                                                                            inputChange={(value) => setEditDescription(value)} autoComplete="off" />
                                                                                <TextInput  label="Important links (Optional)"
                                                                                            inputValue={editImportantLinks}
                                                                                            onKeyDown={e => handleKeyDown(e)}
                                                                                            inputChange={(value) => setEditImportantLinks(value)} autoComplete="off" />
                                                                                <TextInput  label="Contact to the person that is responsible (e.g. email, phone number) (Optional)"
                                                                                            inputValue={editResponsibleContact}
                                                                                            onKeyDown={e => handleKeyDown(e)}
                                                                                            inputChange={(value) => setEditResponsibleContact(value)} autoComplete="off" />

                                                                                <div
                                                                                    className={css.createTaskPopupButton}
                                                                                    onClick={() => {
                                                                                        if(validateEditInput()) {
                                                                                            editTaskHandler()
                                                                                            setSelectedTaskType("")
                                                                                            setTaskName("")
                                                                                            setDescription("")
                                                                                            setImportantLinks("")
                                                                                            setResponsibleContact("")
                                                                                            setOpenedTaskIndex(-1)
                                                                                        }
                                                                                        close();
                                                                                    }}>
                                                                                    Save
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Popup>
                                                                    <div className={css.editButton} onClick={() => {
                                                                        deleteTaskHandler()
                                                                    }}> Delete </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            openedTaskIndex === index ? <div className={css.endingGrayLine} /> : null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            ))
                                            }
                                        </div>
                                        ))
                                    }
                                </div>
                                :
                                <div style={{marginTop:"30px", color: "white"}}>Create your first task to get started.</div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SingleFlowAdmin;