import React from 'react';
import css from "./Text.module.css"

const Text = ({text}) => {
    return (
        <div className={css.text}>
            <p>{text}</p>
        </div>
    );
};

export default Text;