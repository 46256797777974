import './App.css';
import {Route, Routes} from "react-router-dom"
import DashboardAdmin from "./pages/home/admin/DashboardAdmin"
import Signup from "./pages/intro/Signup";
import Login from "./pages/intro/Login";
import NotFound from "./pages/NotFound";
import {AccountContext} from "./pages/auth/Account";
import ForgotPassword from "./pages/intro/ForgotPassword";
import React, {useContext, useEffect, useState} from "react";
import ReactLoading from 'react-loading';
import TeamsAdmin from "./pages/home/admin/TeamsAdmin";
import {getUserData} from "./logic/api/api";
import UserAdmin from "./pages/home/admin/UserAdmin";
import User from "./pages/home/basic/User";
import Teams from "./pages/home/basic/Teams";
import Flows from "./pages/home/basic/Flows";
import AllFlowsAdmin from "./pages/home/admin/AllFlowsAdmin";
import OnboardAdmin from "./pages/home/admin/OnboardAdmin";
import SingleFlowAdmin from "./pages/home/admin/SingleFlowAdmin";
import Dashboard from "./pages/home/basic/Dashboard";
import UserOnboardingDetailsAdmin from "./pages/home/admin/UserOnboardingDetailsAdmin";
import SettingsAdmin from "./pages/home/admin/SettingsAdmin";
import SettingsSearchbarAdmin from "./pages/home/admin/SettingsSearchbarAdmin";
import SearchPageAdmin from "./pages/home/admin/SearchPageAdmin";
import SearchPage from "./pages/home/basic/SearchPage";
import BetaSign from "./components/BetaSign";
import AlertPopup from "./components/AlertPopup";
import Settings from "./pages/home/basic/Settings";

function App() {

  const [logged, setLogged] = useState(false);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true)
  const { getSession, getEmailFromCognito, logout } = useContext(AccountContext);

  useEffect(() => {
      // logout()
    if (process.env.NODE_ENV === 'production') {
        console.log = () => {}
        console.error = () => {}
        console.debug = () => {}
    }

    getSession().then(session => {
        console.log("Session: ", session);
        setLogged(true);
        });
      setLoading(false)

      getEmailFromCognito().then(email => {
          getUserData(email).then(response => {
              setRole(response.data.data.Role.S)
              console.log(response)
              console.log(response.data.data.Role.S)
          }).catch(e => {
              console.log("GET USER DATA ERROR")
              console.log(e)
              return
          })

      })
  }, []);


  return <div>
      <BetaSign/>
      <AlertPopup />
      {/*<Status/>*/}
      {loading ?
          <div style={{textAlign: "center", width: "100%"}}>
              <div style={{margin: "400px auto", width: "1%"}}>
                <ReactLoading type={"spin"} color={"orangered"} height={50} width={50} />
              </div>
          </div>
          :
          <div>
              {logged ?
                    <Routes>
                        <Route path="/" element={role === "Administrator" ? <DashboardAdmin/> : role === "Basic" ? <Dashboard/> : null } exact />
                        <Route path="/onboard" element={role === "Administrator" ? <OnboardAdmin/> : null } exact />
                        <Route path="/search" element={role === "Administrator" ? <SearchPageAdmin/> : role === "Basic" ? <SearchPage/> : null } exact />
                        <Route path="/onboarding/:userEmail" element={role === "Administrator" ? <UserOnboardingDetailsAdmin/> : null } exact />
                        <Route path="/teams/user/:id" element={role === "Administrator" ? <UserAdmin/> : null } exact />
                        <Route path="/teams" element={role === "Administrator" ? <TeamsAdmin/> : null } exact />
                        <Route path="/settings" element={role === "Administrator" ? <SettingsAdmin/> : role === "Basic" ? <Settings/> : null } exact />
                        <Route path="/settings/searchbar" element={role === "Administrator" ? <SettingsSearchbarAdmin/> : null } exact />
                        <Route path="/flows" element={role === "Administrator" ? <AllFlowsAdmin/> : null } exact />
                        <Route path="/flows/:id" element={role === "Administrator" ? <SingleFlowAdmin/> : null } exact />
                        <Route path="/login" element={<Login/>} exact />
                        <Route path="*" element={<NotFound/>} />
                    </Routes>
                    :
                    <Routes>
                        <Route path="/" element={<Login/>} />
                        <Route path="/signup" element={<Signup/>} />
                        <Route path="/login" element={<Login/>} />
                        <Route path="/forgot-password" element={<ForgotPassword/>} />
                        <Route path="*" element={<NotFound/>} />
                    </Routes>
              }
          </div>
      }
  </div>
}

export default App;
