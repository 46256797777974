import React, {createContext, useEffect} from 'react';
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import Pool from "../../logic/auth/UserPool";
import {useNavigate} from "react-router-dom";

const AccountContext = createContext()
const Account = (props) => {

    const navigate = useNavigate();
    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        reject();
                    } else {
                        resolve(session);
                    }
                })
            }
        })
    }

    const getEmailFromCognito = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        console.error(err)
                    } else {
                        user.getUserAttributes(function(err, result) {
                            if (err) {
                                alert(err.message || JSON.stringify(err));
                                return;
                            }
                            for (let i = 0; i < result.length; i++) {
                                if (result[i].getName() === "email") {
                                    let email = result[i].getValue()
                                    resolve(email)
                                }
                            }
                        });
                    }
                })
            }
        })
    }

    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) =>{
            const user = new CognitoUser({Username, Pool})
            const authDetails = new AuthenticationDetails({Username, Password})

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    console.error("onFailure: ", err)
                    reject(err)
                },
                // TODO - co to?
                // newPasswordRequired: (data) => {
                //     console.log("newPasswordRequired: ", data)
                //     resolve(data);
                // }
            })
        })
    }

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
            navigate("/")
            window.location.reload();
        }
    }

    const initForgotPassword = async (Username) => {
        return await new Promise((resolve, reject) =>{
            const user = new CognitoUser({Username, Pool})

            user.forgotPassword( {
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    console.error("onFailure: ", err)
                    reject(err)
                },
                inputVerificationCode: (code) => {
                    console.log("onInputVerificationCode: ", code);
                    resolve(code);
                }
            })
        })
    }

    const confirmNewPassword = async (Username, ConfirmationCode, NewPassword) => {
        return await new Promise((resolve, reject) =>{
            const user = new CognitoUser({Username, Pool})

            user.confirmPassword(ConfirmationCode, NewPassword,  {
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    console.error("onFailure: ", err)
                    reject(err)
                }
            })
        })
    }

    return (
        <AccountContext.Provider value={{ authenticate, getSession, logout, initForgotPassword, confirmNewPassword, getEmailFromCognito }}>
            {props.children}
        </AccountContext.Provider>
    );
};
export { Account, AccountContext };