
import css from "./DropdownInTask.module.css"
import {useEffect, useState} from "react";

const Icon = () => {
    return (
        <svg height="15" width="15" viewBox="0 0 20 20">
            <path fill="#ffffff" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
    );
};

const DropdownInTask = ({ placeHolder, options, onItemClick, selectedValue, isOver }) => {

    const [showMenu, setShowMenu] = useState(false)

    useEffect(() => {
        const handler = () => setShowMenu(false);
        window.addEventListener("click", handler)
        return () => {
            window.removeEventListener("click", handler)
        }
    });

    const handleInputClick = (e) => {
        e.stopPropagation();
        setShowMenu(!showMenu);
    }


    return (
        <div className={css.dropdownContainer}>
            <div onClick={handleInputClick} className={css.dropdownInput}>
                <div className={css.dropdownSelectedValue}>{selectedValue}</div>
                <div className={css.dropdownTools}>
                    <div className={css.dropdownTool}>
                        <Icon />
                    </div>
                </div>
            </div>
            {showMenu && (
                <div className={isOver ? css.dropdownMenuOver : css.dropdownMenu}>
                    {
                        options.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => onItemClick(option)}
                                className={css.dropdownItem} >
                                {option}
                            </div>
                        ))
                    }
                </div>
            )}
        </div>
    );
};

export default DropdownInTask;