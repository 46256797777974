import React, {useEffect, useState} from 'react';
import SideBarAdmin from "../../../components/SideBarAdmin";
import css from "./DashboardAdmin.module.css"
import Pool from "../../../logic/auth/UserPool";
import {loadRunningOnboardingFlowsForAdmin} from "../../../logic/api/api";
import {res} from "react-email-validator";
import Loading from "../../../components/Loading";
import {Link} from "react-router-dom";
import {MdPersonOutline} from "react-icons/md";
import BurgerMenuAdmin from "../../../components/BurgerMenuAdmin";
import CustomChatBot from "../../../components/CustomChatBot";

const DashboardAdmin = () => {

    //zwróć wszystkie running flows dla użytkownika
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [runningFlowsList, setRunningFlowsList] = useState([])
    const [finishedFlowsList, setFinishedFlowsList] = useState([])


    useEffect(() => {
        loadRunningOnboardingFlowsHandler();
    }, []);

    const loadRunningOnboardingFlowsHandler = () => {
        setLoading(true)
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            console.log(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                setEmail(yourEmail)
                                loadRunningOnboardingFlowsForAdmin(yourEmail).then(response => {
                                    let openOnboardings = []
                                    let finishedOnboardings = []
                                    for (let index in response['data']['onboardingFlows']) {
                                        let item = response['data']['onboardingFlows'][index]
                                        item['Status']['S'] === "Open" ? openOnboardings.push(item) : finishedOnboardings.push(item)
                                        console.log(item)
                                    }
                                    console.log(finishedOnboardings)
                                    setRunningFlowsList(openOnboardings)
                                    setFinishedFlowsList(finishedOnboardings)
                                    setLoading(false)
                                }).catch(e => {
                                    console.error("LOAD RUNNING ONBOARDING FLOWS ERROR")
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    return (
        <div>
            <CustomChatBot/>
            <BurgerMenuAdmin/>
            <div style={{display:"flex"}}>
                <style>{'body { background-color: #1d1d1d; }'}</style>
                <SideBarAdmin/>
                <div style={{textAlign: "center", width: "100%"}}>
                    <div className={css.category}>Admin Dashboard</div>
                    <div className={css.greyStripe} />
                    <div className={css.mainBox}>
                        <div className={css.bigText}></div>
                        {/*<div className={css.bigText}>Hello, Mateusz</div>*/}
                        {/*<div className={css.createCompanyContainer}>*/}
                        {/*    <div className={css.titleText}>*/}
                        {/*        Create team/flow to get started*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={css.row}>
                            <div className={css.yourTasksContainer}>
                                <div className={css.titleText}> Currently onboarding employees</div>
                                <div className={css.lightGrayStripe}/>
                                {loading ?
                                    <Loading type={"spinningBubbles"} color={"#ffffff"} />
                                        :
                                    runningFlowsList.length > 0 ? runningFlowsList.map((item, index) => (
                                        <Link key={index} to={"/onboarding/"+item['Executor']['S']}>
                                            <div className={css.itemText}>
                                                <MdPersonOutline size={"16px"}  style={{margin:"0 15px 0 0", display:"inline-block", color:"grey"}}/>
                                                {item['Executor']['S']}
                                            </div>
                                        </Link>
                                    )) :  <div className={css.paragraphText}> No one is onboarding at this moment. </div>
                                }
                            </div>
                            <div className={css.yourTasksContainer}>
                                <div className={css.titleText}> Finished onboardings </div>
                                <div className={css.lightGrayStripe}/>
                                {loading ?
                                    <Loading type={"spinningBubbles"} color={"#ffffff"} />
                                    :
                                    finishedFlowsList.length > 0 ? finishedFlowsList.map((item, index) => (
                                        <Link key={index} to={"/onboarding/"+item['Executor']['S']}>
                                            <div className={css.itemText}>
                                                <MdPersonOutline size={"16px"}  style={{margin:"0 15px 0 0", display:"inline-block", color:"grey"}}/>
                                                {item['Executor']['S']}
                                            </div>
                                        </Link>
                                    )) :  <div className={css.paragraphText}> No onboardings finished yet. </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardAdmin;