import React, {useEffect, useState} from 'react';
import SideBarAdmin from "../../../components/SideBarAdmin";
import css from "./OnboardAdmin.module.css"
import 'react-dropdown/style.css';
import {validate} from "react-email-validator";
import Loading from "../../../components/Loading";
import TextBlackInput from "../../../components/TextBlackInput";
import DropdownBlack from "../../../components/DropdownBlack";
import Pool from "../../../logic/auth/UserPool";
import {loadAllFlows, loadTeams, onboardNewEmployee} from "../../../logic/api/api";
import BurgerMenuAdmin from "../../../components/BurgerMenuAdmin";
import {NavLink} from "react-router-dom";
import useAlert from "../../../logic/alert/useAlert";
import CustomChatBot from "../../../components/CustomChatBot";


const OnboardAdmin = () => {

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [teams, setTeams] = useState([])
    const [flows, setFlows] = useState([])

    const [newEmail, setNewEmail] = useState("")
    const [selectedTeamValue, setSelectedTeamValue] = useState(null)
    const [selectedFlowValue, setSelectedFlowValue] = useState(null)

    const { setAlert } = useAlert()


    const handleKeyDown = (e) => {
        // console.log(e)
    }

    useEffect(() => {
        loadFlowsAndTeamsHandler();
    }, []);

    const loadFlowsAndTeamsHandler = () => {
        setLoading(true)
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            console.log(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                setEmail(yourEmail)
                                loadTeams(yourEmail).then(response => {
                                    let data = []
                                    for (const team of response.data.teams) {
                                        data.push(team['TeamName']['S'])
                                    }
                                    setTeams(data)
                                    console.log(data)
                                    setLoading(false)
                                }).catch(e => {
                                    console.error("LOAD TEAM ERROR")
                                    console.error(e)
                                })

                                loadAllFlows(yourEmail).then(response => {
                                    let data = []
                                    for (const flow of response.data.flows) {
                                        data.push(flow['FlowName']['S'])
                                    }
                                    setFlows(data)
                                    console.log(flows)
                                    setLoading(false)
                                }).catch(e => {
                                    console.error("LOAD FLOWS ERROR")
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    const onItemTeamClick = (option) => {
        setSelectedTeamValue(option)
    }

    const isSelectedTeam = (option) => {
        if(!selectedTeamValue) {
            return false
        }

        return selectedTeamValue === option;
    }

    const getTeamDisplay = () => {
        if (selectedTeamValue) {
            return selectedTeamValue;
        }
        return "Select team";
    };

    const onItemFlowClick = (option) => {
        setSelectedFlowValue(option)
    }

    const isSelectedFlow = (option) => {
        if(!selectedFlowValue) {
            return false
        }

        return selectedFlowValue === option;
    }

    const getFlowDisplay = () => {
        if (selectedFlowValue) {
            return selectedFlowValue;
        }
        return "Select flow";
    };

    const validateInput = () => {
        if(selectedTeamValue === null || selectedFlowValue === null || newEmail === "" || !validate(newEmail) ) {
            setAlert('Incorrect form.', 'error')
            return false
        }
        return true
    }

    const onboardNewEmployeeHandler = () => {
        onboardNewEmployee(email, newEmail, selectedFlowValue, selectedTeamValue).then(response => {
            setAlert('Success! Please notify your employee that email with invitation link might arrive in spam/junk folder.', 'success')
            console.log(response)
        }).catch(e => {
            setAlert('Error, please notify us at hello@pilow.io', 'error')
            console.error("ONBOARD NEW EMPLOYEE ERROR")
            console.error(e)
        })
    }

    return (
        <div>
            <CustomChatBot/>
            <BurgerMenuAdmin/>
            <div style={{display:"flex"}}>
                <style>{'body { background-color: #1d1d1d; }'}</style>
                <div>
                    <SideBarAdmin/>
                </div>
                <div style={{textAlign: "center", width: "100%"}}>
                    <div className={css.category}>Onboard new employee
                    </div>
                    <div className={css.greyStripe} />
                    {loading ?
                        <Loading type={"spinningBubbles"} color={"#ffffff"} />
                        :
                        <div className={css.mainContainer}>
                            { teams.length > 0 && flows.length > 0 ? null :
                                <div className={css.helperText}>To onboard employee you need to:</div>

                            }
                            { teams.length > 0 && flows.length > 0 ?
                            <TextBlackInput onKeyDown={e => handleKeyDown(e)} placeholder="New employee email"
                                             inputValue={newEmail} inputChange={(value) => setNewEmail(value)} autoComplete="off" ></TextBlackInput>
                                : null
                            }
                            { teams.length > 0 && flows.length > 0 ?
                                <DropdownBlack isOver={true} isSelected={isSelectedTeam} onItemClick={onItemTeamClick} placeHolder="Select team" options={teams} selectedValue={getTeamDisplay()} />
                                :
                                null
                            }
                            {teams.length > 0 ?
                                null
                                :
                                <NavLink to="/teams" ><div className={css.createTeamOrFlowButton}> Create team + </div></NavLink>
                            }
                            {teams.length > 0 && flows.length > 0 ?
                                <DropdownBlack isOver={false} isSelected={isSelectedFlow} onItemClick={onItemFlowClick} placeHolder="Select onboarding flow" options={flows} selectedValue={getFlowDisplay()} />
                                :
                                null
                            }
                            {flows.length > 0 ?
                                null
                                :
                                <NavLink to="/flows" ><div className={css.createTeamOrFlowButton}> Create flow + </div></NavLink>
                            }
                            { teams.length > 0 && flows.length > 0 ?
                                <div className={css.onboardButton} onClick={() => {
                                    if(validateInput()) {
                                        onboardNewEmployeeHandler()
                                    }
                                    setNewEmail("")
                                    setSelectedTeamValue(null)
                                    setSelectedFlowValue(null)
                                }}> Onboard </div>
                                :
                                null
                            }

                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default OnboardAdmin;