import React, {useState, useContext, useEffect} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import Button from "../../components/Button";
import { validate } from 'react-email-validator';
import { AccountContext } from "../auth/Account"
import CenteredBoxContainer from "../../components/CenteredBoxContainer";
import TextInput from "../../components/TextInput";
import css from "./IntroStylesheet.module.css"
import ButtonLoading from "../../components/ButtonLoading";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [showEmailVerificationMessage, setShowEmailVerificationMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [buttonLoading, setButtonloading] = useState(false);

    const { authenticate } = useContext(AccountContext)
    const navigate = useNavigate();

    useEffect(() => {
        if(searchParams.get("email")) {
            setShowEmailVerificationMessage(true)
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            login(e)
        }
    }

    const login = (event) => {
        event.preventDefault();
        setButtonloading(true)

        if (checkInputsCorrectness()) {
            authenticate(email, password)
                .then(data => {
                    navigate("/")
                    window.location.reload();
                })
                .catch(err => {
                    console.error("onFailure: ", err)
                    setErrorMessage(err.message)
                    setShowError(true)
                    setButtonloading(false)
                })
        } else {
            setShowError(true)
            setButtonloading(false)
        }

    }

    const checkInputsCorrectness = () => {
        setShowError(false)
        setErrorMessage("")

        if (email === "" || password === "") {
            setErrorMessage("Fields can't be empty.")
            return false
        }

        if (!validate(email)) {
            setErrorMessage("Incorrect email.")
            return false
        }
        return true
    }

    return (
        <CenteredBoxContainer title="Login to pilow" >
            {showEmailVerificationMessage ?
                <div className={css.errorText} style={{margin: "20px 0px"}}>
                    <p>{"Please click on the verification link we've sent you by mail to confirm your account before you login. Please not that it may arrive in spam folder."}</p>
                </div> : null
            }
            <TextInput label="Email" inputValue={email} onKeyDown={e => handleKeyDown(e)} inputChange={(value) => setEmail(value)} autoComplete="off"/>
            <br/>
            <TextInput label="Password" inputValue={password} onKeyDown={e => handleKeyDown(e)} type="password"  inputChange={(value) => setPassword(value)} autoComplete="new-password"/>
            <Link style={{ textDecoration: 'none' }} to="/forgot-password"><label style={{cursor:"pointer"}}> Forgot password?</label></Link>
            <br/>
            {showError ?
                <div className={css.errorText} style={{margin: "20px 0px"}}>
                    <p>{errorMessage + " If you need help, contact us at hello@pilow.io"}</p>
                </div>
                : null}
            <br/>
            {buttonLoading ?
                <ButtonLoading />
                :
                <Button onKeyDown={e => handleKeyDown(e)} handleClick={login} text="Login" filled={true}/>
            }
            <br/>
            <label htmlFor="info">Don't have an account? </label>
            <Link style={{textDecoration: "none"}} to="/signup"><label style={{color: "#2D8BF6"}}>Sign up </label></Link>
        </CenteredBoxContainer>
    );
};

export default Login;