import React, {useEffect} from 'react';
import Pool from "../logic/auth/UserPool";
import {generateChatGPTResult} from "../logic/api/api";
import {res} from "react-email-validator";

const ResponseGPT = (props) => {

    useEffect(() => {
        getResultHandler();
    }, []);

    const getResultHandler = () => {
        console.log(props)
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            alert(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                generateChatGPTResult(yourEmail, props.previousStep.message).then(response => {
                                    props.triggerNextStep({value: response['data'], trigger: 'dynamicallyReachedStep'})
                                }).catch(e => {
                                    props.triggerNextStep({trigger: 'error'})
                                    console.error("GENERATE CHAT GPT RESPONSE ERROR")
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    return (
        <div>Searching...</div>
    )
};


export default ResponseGPT;