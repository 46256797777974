import React, {useEffect, useState} from 'react';
import css from "./SearchPage.module.css"
import {Link} from "react-router-dom";
import TextBlackInput from "../../../components/TextBlackInput";
import Pool from "../../../logic/auth/UserPool";
import {checkModelExistance, generateChatGPTResult} from "../../../logic/api/api";
import BurgerMenuAdmin from "../../../components/BurgerMenuAdmin";
import SideBarUser from "../../../components/SideBarUser";
import BurgerMenuUser from "../../../components/BurgerMenuUser";
import Loading from "../../../components/Loading";



const SearchPageAdmin = () => {

    const [loading, setLoading] = useState(false)
    const [loadingMain, setLoadingMain] = useState(false)
    const [email, setEmail] = useState("")
    const [inputText, setInputText] = useState("")
    const [response, setResponse] = useState("")
    const [modelExistance, setModelExistance] = useState(false)

    useEffect(() => {
        checkModelExistanceHandler();
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            getResultHandler()
        }
        if (e.key === 'Backspace') {
            if(inputText.length === 1) {
                setResponse("")
            }
        }
    }

    const checkModelExistanceHandler = () => {
        setLoadingMain(true)
        const user = Pool.getCurrentUser();
        console.log(user)
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            alert(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                setEmail(yourEmail)
                                checkModelExistance(yourEmail).then(response => {
                                    console.log(response)
                                    setModelExistance(response['data']['data'])
                                    setLoadingMain(false)
                                }).catch(e => {
                                    console.error("CHECK MODEL EXISTANCE ERROR")
                                    setLoadingMain(false)
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    const getResultHandler = () => {
        if (inputText.length > 0) {
            setLoading(true)
            setResponse("")
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        console.error(err)
                    } else {
                        user.getUserAttributes(function(err, result) {
                            if (err) {
                                alert(err.message || JSON.stringify(err));
                                return;
                            }
                            for (let i = 0; i < result.length; i++) {
                                if (result[i].getName() === "email") {
                                    let yourEmail = result[i].getValue()
                                    setEmail(yourEmail)
                                    console.log(yourEmail)
                                    console.log(inputText)
                                    generateChatGPTResult(yourEmail, inputText).then(response => {
                                        console.log(response['data'])
                                        setResponse(response['data'])
                                        setLoading(false)
                                    }).catch(e => {
                                        console.error("GENERATE CHAT GPT RESPONSE ERROR")
                                        setResponse("No results found. Ask admin if search bar is configured (Admin Panel/Settings/Configure search bar). If lack of results still continues, please contact us at hello@pilow.io about this issue. ")
                                        setLoading(false)
                                        console.error(e)
                                    })
                                }
                            }
                        });
                    }
                })
            }
        }
    }

    return (
        <div>
            <BurgerMenuUser/>
            <div style={{display:"flex"}}>
                <style>{'body { background-color: #1d1d1d; }'}</style>
                <div>
                    <SideBarUser/>
                </div>
                <div style={{textAlign: "center", width: "100%"}}>
                    <div className={css.category}>Search</div>
                    <div className={css.greyStripe} />
                    {loadingMain ?
                        <Loading type={"spinningBubbles"} color={"#ffffff"} />
                        :
                        modelExistance ?
                            <div className={css.mainBox}>
                                <TextBlackInput  onKeyDown={e => handleKeyDown(e)} placeholder="Ask question or search the docs..."
                                                 inputValue={inputText} inputChange={(value) => setInputText(value)} autoComplete="off" ></TextBlackInput>
                                {loading ? <Loading type={"spinningBubbles"} color={"#ffffff"} /> : null }
                                {loading ?  <div className={css.loadingText}>Searching for answer...</div> : null }
                                {response ? <div className={css.responseText}>{response}</div> : null}
                            </div>
                            :
                            <div className={css.loadingText}>To use search bar, admin must configure it.</div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SearchPageAdmin;