import React, {useEffect, useState} from 'react';
import ResponseGPT from "./ResponseGPT";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from 'styled-components';
import Pool from "../logic/auth/UserPool";
import {checkModelExistance} from "../logic/api/api";


const CustomChatBot = props => {

    const [modelExistance, setModelExistance] = useState(false)

    useEffect(() => {
        checkModelExistanceHandler();
    }, []);


    const steps = [
        {
            id: '0',
            message: 'Hey. Ask question about company or people in it.',
            trigger: 'search'
        },{
            id: 'search',
            user: true,
            trigger: 'response',
        },
        {
            id: 'response',
            asMessage: true,
            waitAction: true,
            component: <ResponseGPT />,
        },
        {
            id: 'dynamicallyReachedStep',
            message: '{previousValue}',
            trigger: 'askAnother'
        },

        {
            id: 'askAnother',
            message: 'Do you have more questions?',
            trigger: 'search'
        },
        {
            id: 'error',
            message: 'Some error occurred, sorry. Contact us at hello@pilow.io about it.',
            end: true
        }

    ];

    const stepsWithoutModelConfigurated = [
        {
            id: '0',
            message: 'Chatbot need to be configured first. Please check',
            end: true
        }

    ];


    const theme = {
        background: '#f5f8fb',
        fontFamily: 'Helvetica Neue',
        headerBgColor: '#F96553',
        headerFontColor: '#fff',
        headerFontSize: '15px',
        botBubbleColor: '#F96553',
        botFontColor: '#fff',
        userBubbleColor: '#fff',
        userFontColor: '#4a4a4a',
    };

    const checkModelExistanceHandler = () => {
        const user = Pool.getCurrentUser();
        console.log(user)
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            alert(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                checkModelExistance(yourEmail).then(response => {
                                    setModelExistance(response['data']['data'])
                                }).catch(e => {
                                    console.error("CHECK MODEL EXISTANCE ERROR")
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    return (
        modelExistance ?
            <ThemeProvider theme={theme}>
                <ChatBot headerTitle="OnboardingGPT" botAvatar={require('./botAvatar.png')} floating={true} steps={steps} />
            </ThemeProvider>
            :
            null
    );
};

CustomChatBot.propTypes = {

};

export default CustomChatBot;