import React from 'react';
import "./Button.css"

const Button = ({text, filled, handleClick, onKeyDown}) => {
    return (
        <button
            onKeyDown={(e) => onKeyDown(e)}
            onClick={handleClick}
            className={filled ? "button filled" : "button empty"}>
            {text}
        </button>
    );
};

export default Button;

