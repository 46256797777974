import React from 'react';
import SideBarAdmin from "../../../components/SideBarAdmin";
import css from "./UserAdmin.module.css";
import {useParams} from "react-router-dom";

const UserAdmin = () => {

    const { id } = useParams();

    return (
        <div style={{display:"flex"}}>
            <style>{'body { background-color: #1d1d1d; }'}</style>
            <div>
                <SideBarAdmin/>
            </div>
            <div style={{textAlign: "center", width: "100%"}}>
                <div className={css.category}>{id}</div>
                <div className={css.greyStripe} />
                <div className={css.mainBox}>
                </div>
            </div>
        </div>
    );
};

export default UserAdmin;