import axios from 'axios';


const api = 'https://qnkru4ziah.execute-api.us-east-1.amazonaws.com/prod'

const checkCompanyExistence = async (companyName) => {
    const data = {companyName : companyName};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/check-comp-id', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const getUserData = async (email) => {
    const data = {email : email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/get-user', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const createCompany = async (companyName, email) => {
    const data = {companyName: companyName, email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/create-company', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}
const joinAsMember = async (companyName, email, familyName, givenName, role) => {
    const data = {companyName: companyName, email: email, familyName: familyName, givenName: givenName, role: role};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/join-as-member', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const createUser = async (companyName, email, familyName, givenName, role) => {
    const data = {companyName: companyName, email: email, familyName: familyName, givenName: givenName, role: role};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/create-user', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}


const createTeam = async (teamName, email) => {
    const data = {teamName: teamName, email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/create-team', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}
const loadTeams = async (email) => {
    const data = {email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/get-teams', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const addMember = async (email, newMemberEmail, teamName) => {
    const data = {adminEmail: email, newMemberEmail: newMemberEmail, teamName:teamName};
    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/add-member-to-team', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const removeMemberFromTeam = async (email, teamName) => {
    const data = {email: email, teamName: teamName};
    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/remove-member-from-team', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const createFlow = async (newFlowName, selectedTeam, email) => {
    const data = {email:email, newFlowName: newFlowName, selectedTeam: selectedTeam};
    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/create-flow', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const loadAllFlows = async (email) => {
    const data = {email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/get-all-flows', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}


const loadFlow = async (flowName, email) => {
    const data = {flowName: flowName, email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/get-flow', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const createTask = async (email, flowName, selectedTaskType, taskName, description, importantLinks, responsibleContact) => {
    const data = {email:email, flowName: flowName, selectedTaskType: selectedTaskType, taskName: taskName, description: description, importantLinks: importantLinks, responsibleContact: responsibleContact};
    console.log(data)
    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/create-task', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const loadAllTaskForFlow = async (flowName, email) => {
    const data = {flowName: flowName, email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/get-all-tasks', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const loadTask = async (taskName, flowName, email) => {
    const data = {taskName: taskName, flowName: flowName, email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/get-task', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const editTask = async (email, flowName, selectedTaskType, taskName, taskNameOld, description, importantLinks, responsibleContact) => {
    const data = {email:email, flowName: flowName, selectedTaskType: selectedTaskType, taskName: taskName, taskNameOld: taskNameOld, description: description, importantLinks: importantLinks, responsibleContact: responsibleContact};
    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/edit-task', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const deleteTask = async (email, flowName, taskName) => {
    const data = {email: email, flowName: flowName, taskName: taskName};
    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/delete-task', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const addCollaborator = async (email, flowName, collaboratorEmail) => {
    const data = {flowName: flowName, email: email, collaboratorEmail: collaboratorEmail};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/add-collaborator-to-flow', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const removeCollaborator = async (email, flowName, collaboratorEmail) => {
    const data = {flowName: flowName, email: email, collaboratorEmail: collaboratorEmail};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/remove-collaborator-from-flow', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const deleteFlow = async (email, flowName) => {
    const data = {flowName: flowName, email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/delete-flow', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const onboardNewEmployee = async (email, newEmail, flowName, teamName) => {
    const data = {flowName: flowName, email: email, newEmail: newEmail, teamName: teamName};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/start-new-onboarding', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const loadRunningOnboardingFlowsForAdmin = async (email) => {
    const data = {email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/load-running-flows-for-admin', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const loadRunningFlow = async (email, runningFlowId) => {
    const data = {email: email, runningFlowId: runningFlowId};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/load-running-flow-by-id', JSON.stringify(data), {headers: {'Content-Type': 'multipart/form-data'}})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const changeTaskStatus = async (email, status, taskName, runningFlowId) => {
    const data = {email: email, status:status, taskName:taskName, runningFlowId: runningFlowId};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/change-task-status', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const uploadFilesAndTrainModel = async (email, files) => {
    const data = {email: email, files: files};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/upload-files-and-get-presigned-urls', JSON.stringify(data), {headers: {'Content-Type': 'multipart/form-data'}})
            .then((response) => {
                if (response['data']['data'].length > 0) {
                    for (let index in files) {
                        axios.put(response['data']['data'][index], files[index], {headers: {'Content-Type': 'multipart/form-data'}})
                            .then((response) => {
                                axios.post(api+"/start-model-training", JSON.stringify({email:email}))
                                    .then((response) => {
                                        resolve(response)
                                    })
                                    .catch((error) => {
                                        console.error("MODEL TRAINING ERROR")
                                        console.error(error)
                                        reject(error)
                                    });
                            })
                            .catch((error) => {
                                console.error("UPLOAD FILES TO S3 ERROR")
                                console.error(error)
                                reject(error)
                            });
                    }}
             })
            .catch((error) => {
                console.error("GENERATE PRESIGNED URLS ERROR")
                console.error(error)
                reject(error)
            });
    })
}

const generateChatGPTResult = async (email, inputText) => {
    const data = {email: email, inputText: inputText};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/generate-chatgpt-response', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const loadFileNamesFromS3 = async (email) => {
    const data = {email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/get-chatgpt-files-from-s3', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const checkModelExistance = async (email) => {
    const data = {email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/check-model-existance', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const removeAllFiles = async (email) => {
    const data = {email: email};

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/remove-all-chatgpt-files', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}

const markAsFinishedOnboarding = async (email, onboardingId) => {
    const data = {email: email, onboardingId: onboardingId };

    return await new Promise((resolve, reject) => {
        axios
            .post(api+'/finish-onboarding', JSON.stringify(data))
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    })
}




export {checkCompanyExistence, createCompany, createUser, createTeam, loadTeams, addMember,
    joinAsMember, getUserData, removeMemberFromTeam, createFlow, loadAllFlows, loadFlow, createTask,
    loadAllTaskForFlow, loadTask, editTask, deleteTask, addCollaborator, removeCollaborator, deleteFlow,
    onboardNewEmployee, loadRunningOnboardingFlowsForAdmin, loadRunningFlow, changeTaskStatus, uploadFilesAndTrainModel,
    generateChatGPTResult, loadFileNamesFromS3, checkModelExistance, removeAllFiles, markAsFinishedOnboarding };
