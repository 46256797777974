import React, {useEffect, useState} from 'react';
import SideBarAdmin from "../../../components/SideBarAdmin";
import css from "./SettingsSearchbarAdmin.module.css"
import {Link} from "react-router-dom";
import Dropzone from 'react-dropzone'
import {loadFileNamesFromS3, uploadFilesAndTrainModel, removeAllFiles} from "../../../logic/api/api";
import Pool from "../../../logic/auth/UserPool";
import Loading from "../../../components/Loading";
import BurgerMenuAdmin from "../../../components/BurgerMenuAdmin";
import useAlert from "../../../logic/alert/useAlert";
import CustomChatBot from "../../../components/CustomChatBot";

const SettingsSearchbarAdmin = () => {

    const [email, setEmail] = useState("")
    const [files, setFiles] = useState([])
    const [currentFilesOnS3, setCurrentFilesOnS3] = useState([])
    const [loading, setLoading] = useState(false)
    const { setAlert } = useAlert()

    useEffect(() => {
        loadFileNamesFromS3Handler();
    }, []);

    const uploadFilesAndTrainModelHandler = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            console.log(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                setEmail(yourEmail)
                                if (files === []) {
                                    setAlert('No files selected', 'error')
                                    return;
                                }
                                setLoading(true)
                                uploadFilesAndTrainModel(yourEmail, files).then(response => {
                                    setLoading(false)
                                    setAlert('Success', 'success')
                                    setFiles([])
                                    loadFileNamesFromS3Handler()
                                }).catch(e => {
                                    setLoading(false)
                                    setFiles([])
                                    setAlert('Error with uploading files. Please contact us at hello@pilow.io', 'error')
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    const loadFileNamesFromS3Handler = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            console.error(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                setEmail(yourEmail)
                                loadFileNamesFromS3(yourEmail, files).then(response => {
                                    setCurrentFilesOnS3(response['data']['data'])
                                    console.log(response)
                                }).catch(e => {
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    const removeAllFilesHandler = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            console.error(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                setEmail(yourEmail)
                                removeAllFiles(yourEmail).then(response => {
                                    setCurrentFilesOnS3([])
                                    setAlert("Success", 'success')
                                }).catch(e => {
                                    setAlert("Error, contact us at hello@pilow.io", 'error')
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    return (
        <div>
            <CustomChatBot/>
            <BurgerMenuAdmin/>
            <div style={{display:"flex"}}>
                <style>{'body { background-color: #1d1d1d; }'}</style>
                <div>
                    <SideBarAdmin/>
                </div>
                <div style={{textAlign: "center", width: "100%"}}>
                    <div className={css.category}>Settings - search bar</div>
                    <div className={css.greyStripe} />
                    {loading ? <Loading type={"spinningBubbles"} color={"#ffffff"} /> : null }
                    {loading ? <div className={css.loadingText}>Training search bar on new documents...</div> : null }

                    {loading ? null :
                        <>
                            <div className={css.dropzone}>
                                <Dropzone onDrop={acceptedFiles => setFiles(acceptedFiles)}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <p className={css.dragAndDropText}>Drag and drop some files here, or click to select files</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                            <div className={css.fileList}>
                                <ul>
                                    {files.map((file, index) => (
                                        <li key={index}>{file.path}</li>
                                    ))}
                                </ul>
                                {files.length > 0 ? <div className={css.buttonSaveFiles} onClick={uploadFilesAndTrainModelHandler}>Save files</div> : null }
                            </div>
                        </>
                    }
                    {
                        currentFilesOnS3.length > 0 ?
                            <div className={css.fileList}>
                                <div className={css.filesCurrentlyText}>Files currently uploaded:</div>
                                <ul>
                                    {currentFilesOnS3.map((filename, index) => (
                                        <li key={index}>{filename}</li>
                                    ))}
                                </ul>
                                <div className={css.removeFilesButton} onClick={e => removeAllFilesHandler()}>Remove all files</div>
                            </div> : null
                    }
                </div>
            </div>
        </div>
    );
};

export default SettingsSearchbarAdmin;