import React from 'react';
import css from "./ButtonLoading.module.css"
import ReactLoading from "react-loading";

const ButtonLoading = () => {
    return (
        <button
            className={css.button}>
            <ReactLoading className={css.loadingComponent} type={"spin"} color={"white"} height={20} width={20} />
        </button>
    );
};

export default ButtonLoading;

