import React from 'react';
import css from "./CenteredBoxContainer.module.css"
import Title from "./Title";

const CenteredBoxContainer = ({children, title}) => {
    return (
        <div className={css.center}>
            <div className={css.box}>
                <div className={css.logoContainer}>
                    <img className={css.image} src={require('./blackPilowLogo.png')} />
                </div>
                <Title text={title}/>
                <div className={css.space}>
                    {children}
                </div>
                <label style={{textAlign: "left", display: "block", color: "#5D5D5B"}} htmlFor="info">This
                    webiste is protected by reCAPTCHA and there are regulations with Privacy policy and
                    Terms of Service.</label>
            </div>
        </div>
    );
};

export default CenteredBoxContainer;