import React, {useEffect, useState} from 'react';
import css from "./UserOnboardingDetailsAdmin.module.css"
import SideBarUser from "../../../components/SideBarUser";
import Loading from "../../../components/Loading";
import {MdCheckCircle, MdOutlineErrorOutline, MdKeyboardBackspace, MdCheckCircleOutline, MdExpandLess, MdExpandMore} from "react-icons/md";
import {Link, NavLink, useNavigate, useParams} from "react-router-dom";
import Pool from "../../../logic/auth/UserPool";
import {
    getUserData,
    loadAllTaskForFlow,
    loadFlow,
    loadRunningFlow,
    changeTaskStatus,
    markAsFinishedOnboarding
} from "../../../logic/api/api";
import DropdownInTask from "../../../components/DropdownInTask";
import {res} from "react-email-validator";
import SideBarAdmin from "../../../components/SideBarAdmin";
import BurgerMenuAdmin from "../../../components/BurgerMenuAdmin";

const UserOnboardingDetailsAdmin = () => {

    const { userEmail } = useParams();
    const navigate = useNavigate();

    const [activeOnboardingId, setActiveOnboardingId] = useState(null)
    const [runningFlowData, setRunningFlowData] = useState(null)
    const [flowData, setFlowData] = useState(null)
    const [tasksList, setTasksList] = useState([])
    const [categories, setCategories] = useState(null)
    const [loading, setLoading] = useState(true)
    const [openedTaskIndex, setOpenedTaskIndex] = useState(-1)

    useEffect(() => {
        loadOnboardingFlowForUser();
    }, []);

    const returnStatusIcon = (taskName) => {
        for (const index in runningFlowData['TasksState']['L']) {
            if (runningFlowData['TasksState']['L'][index]['M']['TaskName']['S'] === taskName) {
                if (runningFlowData['TasksState']['L'][index]['M']['Status']['S'] === "Open") {
                    return <MdCheckCircleOutline size={"16px"}  style={{margin:"0 15px 0 0", display:"inline-block", color:"grey"}}/>
                }

                if (runningFlowData['TasksState']['L'][index]['M']['Status']['S'] === "Closed") {
                    return <MdCheckCircle size={"16px"}  style={{margin:"0 15px 0 0", display:"inline-block", color:"#58a182"}}/>
                }

                if (runningFlowData['TasksState']['L'][index]['M']['Status']['S'] === "Problem") {
                    return <MdOutlineErrorOutline size={"16px"}  style={{margin:"0 15px 0 0", display:"inline-block", color:"#f06a6a"}}/>
                }

            }
        }
    }

    const returnStatusText = (taskName) => {
        for (const index in runningFlowData['TasksState']['L']) {
            if (runningFlowData['TasksState']['L'][index]['M']['TaskName']['S'] === taskName) {
                return runningFlowData['TasksState']['L'][index]['M']['Status']['S']
            }
        }
    }

    const markAsFinishedHandler = () => {
        setLoading(true)
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err)
                } else {
                    user.getUserAttributes(function(err, result) {
                        if (err) {
                            alert(err.message || JSON.stringify(err));
                            return;
                        }
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].getName() === "email") {
                                let yourEmail = result[i].getValue()
                                markAsFinishedOnboarding(yourEmail, activeOnboardingId).then(response => {
                                    navigate("/")
                                    setLoading(false)
                                }).catch(e => {
                                    setLoading(false)
                                    console.error("LOAD USER ERROR")
                                    console.error(e)
                                })
                            }
                        }
                    });
                }
            })
        }
    }

    const loadOnboardingFlowForUser = () => {
        setLoading(true)
        getUserData(userEmail).then(response => {
            if (response['data']['data']['RunningFlow']) {
                let runningFlowId = response['data']['data']['RunningFlow']['S']
                setActiveOnboardingId(runningFlowId)
                if (runningFlowId) {
                    loadRunningFlow(userEmail, runningFlowId).then(response => {
                        let runningFlowResponse = response['data']['data']
                        setRunningFlowData(runningFlowResponse)
                        let flowName = runningFlowResponse['FlowName']['S']
                        loadFlow(flowName, userEmail).then(response => {
                            let flow = response['data']['data']
                            setFlowData(flow)
                            let flowName = flow['FlowName']['S']

                            if (flow['Tasks']) {
                                loadAllTaskForFlow(flowName, userEmail).then(response => {
                                    //TODO what if task not found?
                                    let tasks = response['data']['data']['tasks']
                                    let categories = response['data']['data']['categories']
                                    setTasksList(tasks)
                                    setCategories(categories)
                                    setLoading(false)
                                }).catch(e => {
                                    console.error("LOAD TASK ERROR")
                                    console.error(e)
                                    setLoading(false)
                                })
                            } else {
                                setLoading(false)
                            }

                        }).catch(e => {
                            console.error("LOAD FLOW ERROR")
                            console.error(e)
                        })

                    }).catch(e => {
                        console.error("LOAD RUNNING FLOW ERROR")
                        console.error(e)
                        setLoading(false)
                    })
                } else {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        }).catch(e => {
            console.error("LOAD USER ERROR")
            console.error(e)
        })
    }

    return (
        <div>
            <BurgerMenuAdmin/>
            <div style={{display:"flex"}}>
                <style>{'body { background-color: #1d1d1d; }'}</style>
                <div>
                    <SideBarAdmin/>
                </div>
                {loading ?
                    <Loading type={"spinningBubbles"} color={"#ffffff"} />
                    :
                    activeOnboardingId ?
                        <div style={{textAlign: "center", width: "100%"}}>
                            <div className={css.category}>
                                <Link to="/"><MdKeyboardBackspace size={"20px"}  style={{margin:"0 15px 0 0", display:"inline-block", color:"white"}}/></Link>
                                <div style={{textDecoration:"underline", display:"inline-block"}}>{userEmail}</div>  onboarding status</div>
                            <div className={css.greyStripe} />
                            <div>
                                {typeof tasksList !== 'undefined' && tasksList.length > 0 ?
                                    <div className={css.tasksContainer}>
                                        {categories.map((categoryName, index) => (
                                            <div key={index}>
                                                <div className={css.taskCategoryTitle} >
                                                    {categoryName}
                                                </div>
                                                {tasksList.map((item, index) => (
                                                    item['TaskCategory']['S'] === categoryName ?
                                                        <div key={index}>
                                                            <div style={{color:"#ffffff", textDecoration: "none"}} onClick={e => {
                                                                e.preventDefault()
                                                                // fillTaskBox(item['TaskName']['S'], item['TaskCategory']['S'], item['Description']['S'], item['ImportantLinks']['S'], item['ResponsibleContact']['S'])
                                                                openedTaskIndex === index ? setOpenedTaskIndex(-1) : setOpenedTaskIndex(index)
                                                            }}>
                                                                <div className={css.taskRectangleBox} style={openedTaskIndex === index ? {border: "none"} : {borderBottom: "0.5px solid #414141"}} >
                                                                    <div className={css.leftIcon}>{returnStatusIcon(item['TaskName']['S'])}</div>
                                                                    <div className={css.taskName}>{item['TaskName']['S']} </div>
                                                                    <div className={css.rightIcon}>
                                                                        {openedTaskIndex === index ?
                                                                        <MdExpandLess size={"14px"}  style={{margin:"0 7px 0 7px", display:"inline-block", color:"white"}}/>
                                                                        :
                                                                        <MdExpandMore size={"14px"}  style={{margin:"0 7px 0 7px", display:"inline-block", color:"white"}}/>
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                openedTaskIndex === index ?
                                                                    <div className={css.openedTaskBoxContainer}>
                                                                        Description:
                                                                        <div className={css.textLineContainer}>
                                                                            {item['Description']['S']}
                                                                        </div>
                                                                        {'ImportantLinks' in item ?
                                                                            <>
                                                                                Important Links:
                                                                                <div className={css.textLineContainer}>
                                                                                    <a style={{color: "lightskyblue", textDecoration:"underline"}} href={item['ImportantLinks']['S']}>{item['ImportantLinks']['S']}</a>
                                                                                </div>
                                                                            </>
                                                                            : null
                                                                        }
                                                                        {'ResponsibleContact' in item ?
                                                                            <>
                                                                                Person responsible:
                                                                                <div className={css.textLineContainer}>
                                                                                    {item['ResponsibleContact']['S']}
                                                                                </div>
                                                                            </>
                                                                            : null
                                                                        }
                                                                        Status:
                                                                        <div className={css.textLineContainer}>
                                                                            {returnStatusText(item['TaskName']['S'])}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                openedTaskIndex === index ? <div className={css.endingGrayLine} /> : null
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                ))
                                                }
                                            </div>
                                        ))
                                        }
                                    </div>
                                    :
                                    <div style={{marginTop:"30px", color: "white"}}>Create your first task to get started.</div>
                                }
                            </div>
                            {runningFlowData['Status']['S'] === 'Finished' ? null :
                                <div className={css.finishOnboardingButton} onClick={() => markAsFinishedHandler()}>Mark onboarding as finished</div>
                            }
                        </div>
                        :
                        <p style={{color:"white"}}>User already onboarded.</p>
                        // <p style={{color:"white"}}> TODO info about finished onboarding or tasks TODO - this state is when no onaboarding is attached to user </p>
                }
            </div>
        </div>
    );
};

export default UserOnboardingDetailsAdmin;