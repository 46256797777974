import React from 'react';
import css from "./TextInput.module.css"

const TextInput = ({label, inputValue, inputChange, type, autoComplete, disabled, onKeyDown}) => {
    return (
        <div className={css.inputBox}>
            <label>{label}</label>
            <input className={css.textInput} value={inputValue}
                   onChange={(event) => inputChange(event.target.value)}
                   type={type}
                   onKeyDown={(e) => onKeyDown(e)}
                   style={disabled ?  {pointerEvents: "none"} : {}}
                   autoComplete={autoComplete}>
            </input>
        </div>
    );
};

export default TextInput;